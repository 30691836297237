import * as React from "react";
import type { HeadFC } from "gatsby";
import styled from "styled-components";

import { Container } from "../components/Grid";
import { Layout } from "../components/Layout";

const ScMain = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #020203;

  .post-full-content {
    background-color: transparent;
  }
`;

const TCPage = () => {
  return (
    <Layout>
      <ScMain>
        <Container>
          <article className="post-full post no-image page no-image">
            <h1>Margin Facility Agreement</h1>
            <section className="post-full-content">
              <div className="post-content">
                <p>
                  <em>
                    NOTE: This agreement is superseded by the{" "}
                    <a href="/terms-of-service-v5/">
                      Terms of Service Version 5
                    </a>{" "}
                    effective September 2, 2021.
                  </em>
                </p>
                <p>
                  <strong>FACILITY AGREEMENT</strong>
                </p>
                <p>
                  This Facility Agreement (this “<strong>Agreement</strong>”) is
                  a legal and binding contract between Fons Vitae LLC, a Saint
                  Vincent and the Grenadines limited liability company with
                  Limited Liability Company Number 709 LLC 2020, its successors
                  and assigns  (hereinafter "<strong>Fons Vitae</strong>", “
                  <strong>our</strong>”, “<strong>us</strong>” or “
                  <strong>we</strong>”), and the party or parties (the "
                  <strong>Borrower</strong>", or “<strong>you</strong>”)
                  executing this Agreement.
                </p>
                <p>
                  The registration and use of a Margin Account in the Trading
                  Platform implies acceptance of this Agreement as well as the
                  conditions and provisions included in the{" "}
                  <a href="/privacy-policy-v1/">Privacy Policy</a>, Power
                  Fintech LLC’s{" "}
                  <a href="/terms-of-service-v5/">Terms of Service</a>,{" "}
                  <a href="/api-license-agreement/">API License Agreement</a>,
                  and the{" "}
                  <a href="/risk-disclosure-statement/">
                    Risk Disclosure Statement
                  </a>
                  (together the “<strong>Terms</strong>”). The parties will
                  accept and be bound by the Terms on acceptance of the terms
                  and conditions contained herein.
                </p>
                <p>
                  In consideration of Fons Vitae agreeing to provide a Margin
                  Facility to you in connection with the purchase of Virtual
                  Assets and/or Virtual Asset Derivatives, the Borrower agrees
                  that the following rights and obligations will govern the
                  relationship between Fons Vitae and the Borrower.
                </p>
                <ol>
                  <li>
                    <strong>
                      <strong>
                        <strong>DEFINITIONS AND INTERPRETATION</strong>
                      </strong>
                    </strong>
                  </li>
                </ol>
                <p>
                  <strong>1.1. Definitions</strong>
                </p>
                <p>
                  \"<strong>Agreement</strong>\" shall include this Agreement
                  and all other agreements and authorizations executed by
                  Borrower in connection with the maintenance of a Margin
                  Account and the access to the Margin Facility.
                </p>
                <p>
                  \"<strong>Bankrupt</strong>\" shall mean in the case of (a) a
                  body corporate, a person who is being wound up or cease to
                  carry on a business, who enters into a compromise or
                  arrangement with its creditors, whose property is in the
                  possession or under the control of a receiver (whether
                  appointed by a court, or otherwise), who is under
                  administration or a scheme of arrangement, or who is subject
                  to any analogous event, or (b) a natural person, a person who
                  is bankrupt, or who is subject to any events analogous to
                  those described under item (a);
                </p>
                <p>
                  \"<strong>Borrower</strong>\", or “<strong>you</strong>” shall
                  mean the party (or parties) who have agreed to be bound by the
                  terms of this Agreement.
                </p>
                <p>
                  “<strong>Futures</strong>” shall mean Virtual Asset
                  Derivatives that give buyers the obligation to buy or sell a
                  Virtual Asset at an agreed-upon price on a specific date.
                </p>
                <p>
                  “<strong>Margin</strong>” shall mean the funds held in the
                  Margin Account as Collateral to maintain a financed trading
                  position.
                </p>
                <p>
                  “<strong>Margin Account</strong>” shall mean any of your
                  accounts maintained with us through the Trading Platform for
                  the purpose of the Margin Facility.
                </p>
                <p>
                  “<strong>Margin Call</strong>” shall mean the circumstances
                  described in Section 4.3.
                </p>
                <p>
                  “<strong>Margin Facility</strong>” shall mean the facility
                  granted or to be granted by us to you for the purchase of
                  Virtual Assets and/or Virtual Asset Derivatives pursuant to
                  this Agreement.
                </p>
                <p>
                  “<strong>Margin Limit</strong>” shall mean the maximum Margin
                  Facility amount granted to the Borrower, as Fons Vitae may at
                  its absolute discretion determine from time to time.
                </p>
                <p>
                  “<strong>Minimum Margin Requirements</strong>” shall mean the
                  minimum amount of funds to hold in the Margin Account to open
                  and/or maintain a financed trading position.
                </p>
                <p>
                  “<strong>KYC/AML &#x26; CTF Policy</strong>” shall mean the
                  policies and procedures implemented by Fons Vitae to detect
                  and prevent money laundering, terrorist financing, and
                  corruption.
                </p>
                <p>
                  “<strong>Options</strong>” shall mean Virtual Asset
                  Derivatives that give buyers the right, but not the
                  obligation, to buy or sell a Virtual Asset at an agreed-upon
                  price and date, which may be purchased or sold through the
                  Trading Platform.
                </p>
                <p>
                  “<strong>Outstanding Amounts</strong>” shall mean at any time
                  all amounts (whether in respect of advances, interest, fees or
                  otherwise) owing or payable at that time by the Borrower to
                  Fons Vitae under this Agreement.
                </p>
                <p>
                  “<strong>Perpetual Swaps</strong>” shall mean Virtual Asset
                  Derivatives without expiry date that allow buyers to speculate
                  and make a profit or loss by reference to fluctuations in the
                  value or price of Virtual Assets.
                </p>
                <p>
                  “<strong>PowerTrade</strong>” shall mean Power Fintech LLC, or
                  its subsidiaries and affiliates and their successors and
                  assigns.
                </p>
                <p>
                  “<strong>Prohibited Jurisdiction</strong>” shall mean (i) the
                  United States, Cuba, Syria, Iran, North Korea; (ii) any state,
                  country or other jurisdiction that is sanctioned and/or
                  embargoed by the United States of America; (iii) a
                  jurisdiction where it would be illegal according to local law
                  or regulation for you to access or use the Services; or (iv)
                  where the provision or availability of the Services is
                  prohibited or contrary to local law or regulation, or could
                  subject Fons Vitae or PowerTrade to any local registration,
                  regulatory or licensing requirements.
                </p>
                <p>
                  “<strong>Trading Platform</strong>” shall mean the trading
                  platform provided by PowerTrade for purchasing, selling and
                  trading Virtual Assets and/or Virtual Assets Derivatives.
                </p>
                <p>
                  “<strong>U.S.</strong>” or “<strong>US</strong>” or “
                  <strong>United States</strong>” shall mean all states of the
                  United States of America, the District of Columbia;  the
                  Commonwealth of Puerto Rico; the U.S. Virgin Islands; Guam;
                  the Commonwealth of the Northern Mariana Islands; and all
                  other territories and possessions of the United States of
                  America.
                </p>
                <p>
                  “<strong>US Person</strong>” shall mean (i) a U.S. citizen;
                  (ii) a U.S. lawful permanent resident; (iii) a protected
                  individual under section 1324b(a)(3) of the U.S. Immigration
                  and Nationality Act, or individual who holds a passport issued
                  by the United States Government; (iv) a corporation, company,
                  partnership, or other legal entity established or organized in
                  or under the Laws of the United States; (v) an individual, a
                  corporation, company, partnership, or other legal entity which
                  engages in a trade or business in the United States and
                  generates income from sources within the United States
                  connected with the conduct of that trade or business; (vi) any
                  estate of a decedent who was a U.S. citizen or a U.S. lawful
                  permanent resident; or a protected individual under section
                  1324b(a)(3) of the U.S. Immigration and Nationality Act; (vii)
                  any trust if a court within the United States is able to
                  exercise primary supervision over the administration of the
                  trust, and/or one or more US Persons have the authority to
                  control all substantial decisions of the trust, and/or one or
                  US Persons are designated as settlors, trustees, protectors or
                  beneficiaries; (viii) any Person organized or incorporated
                  outside the United States and the Territory or Insular
                  Possession of the United States in which any of the foregoing,
                  whether singularly or in the aggregate, directly or indirectly
                  holds a 50 percent or greater equity interest by votes or
                  value, holds a majority of seats or memberships on the board
                  of directors of the entity, or authorizes, establishes,
                  directs, or otherwise controls the actions, policies,
                  personnel decisions, or day-to-day operations of the Person;
                  or (ix) any person who is subject to taxes in the US.
                </p>
                <p>
                  “<strong>Virtual Asset Derivatives</strong>” shall mean
                  contracts between two or more parties whose value is based on
                  an underlying Virtual Asset. Virtual Asset Derivatives include
                  but are not limited to Options, Perpetual Swaps, Futures, and
                  other instruments such as forwards, and contracts for
                  difference.
                </p>
                <p>
                  “<strong>Virtual Assets</strong>'' shall mean encrypted
                  currencies or digital assets or digital tokens or virtual
                  currencies or cryptocurrencies which are based on distributed
                  ledger technology and can be digitally traded, or transferred.
                  For the avoidance of doubt, Bitcoin and Ether are Virtual
                  Assets.
                </p>
                <p>
                  <strong>1.2. Construction.</strong> In this Agreement, unless
                  the context otherwise requires
                </p>
                <ol>
                  <li>
                    words importing the singular include the plural and vice
                    versa;
                  </li>
                  <li>
                    words importing a gender include both gender and the neuter;
                  </li>
                  <li>
                    words importing persons include companies, associations and
                    bodies of persons whether corporate or not;
                  </li>
                  <li>
                    the words: “may” shall be construed as permissive; “shall”
                    or “will” shall be construed as imperative;
                  </li>
                  <li>
                    the term “include” or “includes” means includes, without
                    limitation, and “including” means including, without
                    limitation;
                  </li>
                  <li>
                    the terms “hereof”, “herein” and “hereunder” refer to this
                    Agreement as a whole and not to any particular provision of
                    this Agreement; and
                  </li>
                  <li>
                    the headings contained in this Agreement are for reference
                    purposes only, and shall not affect in any way the meaning
                    or interpretation of this Agreement.
                  </li>
                </ol>
                <p>
                  <strong>
                    2.{" "}
                    <strong>
                      <strong>FACILITY TERMS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>2.1.\tMargin Facility</strong>. Subject to the terms
                  and conditions of this Agreement, Fons Vitae hereby agrees at
                  the request of the Borrower to make available to the Borrower
                  a revolving credit facility of up to the Margin Limit.
                </p>
                <p>
                  <strong>2.2.\tReduction, Variation and Cancellation</strong>.
                  Fons Vitae shall have the right to reduce, cancel or vary and
                  from time to time review the Margin Facility and nothing in
                  this Agreement shall be deemed to impose on Fons Vitae any
                  obligation at law or in equity to make or continue to make
                  available to the Borrower the Margin Facility.
                </p>
                <p>
                  <strong>2.3.\tPurpose of the Margin Facility</strong>. The
                  Margin Facility shall only be used by the Borrower for
                  financing the purchase of Virtual Assets and/or Virtual Asset
                  Derivatives through the Trading Platform.
                </p>
                <p>
                  <strong>2.4.\tMargin Account</strong>. The Margin Account
                  shall be used for the purchase and sale of Virtual Assets
                  and/or Virtual Asset Derivatives through the Trading Platform
                  in line with the <a href="%22/terms/%22">Terms of Service</a>{" "}
                  of PowerTrade and for the carrying of Virtual Assets to be
                  used as Margin.
                </p>
                <p>
                  <strong>2.5.\tIdentity Verification</strong>. In order to open
                  a Margin Account, and grant you a Margin Facility, we will
                  request certain information and documentation that will allow
                  us to identify you in line with the KYC/AML &#x26; CTF Policy.
                  You must provide us information that is accurate, complete,
                  and current at all times. Failure to do so constitutes a
                  breach of this Agreement, which may result in immediate
                  termination of this Agreement. You also agree to provide us,
                  when opening a Margin Account and as requested on an ongoing
                  basis, with any additional information. For the purposes of
                  identity verification and the detection and prevention of
                  money laundering, terrorist financing, fraud, or any other
                  financial crime, including without limitation, a copy of your
                  government-issued photo ID, a photograph of you holding your
                  government-issued photo ID, a video-verification of you
                  holding your government-issued photo ID, evidence of your
                  residential address (such as a utility bill), evidence of the
                  source of funds, evidence of the source of wealth, and
                  evidence of your good character. You authorize us to keep a
                  record of such information and to make the inquiries, whether
                  directly or through third parties, that we consider necessary
                  to verify your identity or protect you and others against
                  fraud or other financial crime, and to take action we
                  reasonably deem necessary based on the results of such
                  inquiries. When we carry out these inquiries, you acknowledge
                  and agree that your personal information may be disclosed to
                  regulatory or enforcement agencies.
                </p>
                <p>
                  <strong>
                    3.{" "}
                    <strong>
                      <strong>BORROWER REPRESENTATIONS AND WARRANTIES</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>3.1. \tRepresentations and Warranties.</strong> The
                  Borrower represents and warrants:
                </p>
                <p>
                  a). you are at least 18 years old or of legal age to form a
                  binding contract under applicable law, are an individual,
                  legal person or other organization with full legal capacity
                  and authority to enter into this Agreement;
                </p>
                <p>
                  b). if you are entering into this Agreement on behalf of a
                  legal entity of which you are an employee or agent, you have
                  all necessary rights and authority to bind such legal entity,
                  and (i) you are legally permitted to use the Margin Facility
                  in your jurisdiction, and (ii) you are legally permitted to
                  own Virtual Assets in your jurisdiction;
                </p>
                <p>c). if you are a natural person, you are not Bankrupt;</p>
                <p>
                  d). if you are a company, you are duly incorporated and
                  validly existing under the Laws of your jurisdiction of
                  incorporation, you are not Bankrupt, and that no step has been
                  taken that might result in any of these statements becoming
                  incorrect;
                </p>
                <p>
                  e). you have taken such independent legal, financial and
                  taxation advice in connection with your entry into this
                  Agreement as you reasonably think fit;
                </p>
                <p>
                  f). you are responsible for ensuring compliance with the laws
                  of your jurisdiction and acknowledge that Fons Vitae is not
                  liable for your compliance with such laws,
                </p>
                <p>
                  g). your use of the Margin Facility does not constitute a
                  breach of the laws of your jurisdiction;
                </p>
                <p>
                  h). you understand the inherent risks associated with Virtual
                  Assets, Virtual Asset Derivatives, and trading Virtual Assets
                  and Virtual Asset Derivatives;
                </p>
                <p>
                  i). you have a working understanding of the usage of Virtual
                  Assets, smart contract based tokens, and blockchain-based
                  software systems;
                </p>
                <p>
                  j). you have sufficient investment knowledge and experience
                  and the capacity to take risks arising from trading in
                  derivatives;
                </p>
                <p>
                  k). you have knowledge and experience in highly volatile
                  markets;
                </p>
                <p>
                  l). you trade with funds you can afford to lose, and have a
                  high-risk tolerance;
                </p>
                <p>
                  m). you will not carry out any activity that (i) involves
                  proceeds from any illegal or unlawful activity (including
                  money laundering or terrorism financing); or (iii) violates,
                  or could violate, any applicable law;
                </p>
                <p>
                  n). you will not be involved or initiate any form of market
                  manipulation;
                </p>
                <p>
                  o). you own and have full control of  the Virtual Asset wallet
                  address used for withdrawals of your Account;
                </p>
                <p>
                  p). you are the legal owner (or an authorized agent of the
                  legal owner) of the funds you deposit to your Margin Account,
                  and that these funds are derived from a legitimate source;
                </p>
                <p>
                  q). you are not a national and/or resident of a Prohibited
                  Jurisdiction;
                </p>
                <p>
                  r). you are not by reason of your nationality, domicile,
                  citizenship, residence or otherwise subject to the laws of a
                  Prohibited Jurisdiction;
                </p>
                <p>s). you are not a US Person; and</p>
                <p>
                  t). you are are not, and have not been involved in a
                  transaction with a person who is, on any trade or economic
                  sanctions lists, including, but not limited to, the UN
                  Security Council Sanctions list, designated as a “Specially
                  Designated National” by OFAC (Office of Foreign Assets Control
                  of the U.S. Treasury Department) or placed on the U.S.
                  Commerce Department’s “Denied Persons List”. Fons Vitae
                  maintains the right to restrict or deny the provision of a
                  Margin Facility in certain countries and/or to certain natural
                  persons and/or juristic persons at its sole discretion.
                </p>
                <p>
                  <strong>3.2. \tBorrower Undertaking.</strong> You hereby agree
                  and undertake to pay all sums due to the Fons Vitae and
                  otherwise observe, perform and comply with all the covenants,
                  undertakings, stipulations, terms and conditions and
                  obligations under this Agreement.
                </p>
                <p>
                  <strong>
                    4.{" "}
                    <strong>
                      <strong>MARGIN</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>4.1.\tMargin</strong>. The Margin deposited by the
                  Borrower in the Margin Account shall be in the form of Virtual
                  Assets and/or such other assets which are acceptable to Fons
                  Vitae.
                </p>
                <p>
                  <strong>4.2.\tMinimum Margin Requirements</strong>. Minimum
                  Margin Requirements as well as rules and requirements for
                  Margin Accounts are set forth at [WEBSITE]. We may, in our
                  discretion, update the Minimum Margin Requirements and the
                  rules and requirements for Margin Accounts at any time by
                  giving not less than twelve (12) hours’ notice to the
                  Borrower. The Borrower shall be deemed to have agreed to such
                  changes or updates thereof unless the Borrower otherwise
                  expressly notifies Fons Vitae in writing within three twelve
                  (12) hours of receipt of such notice.
                </p>
                <p>
                  <strong>4.3.\tMargin Call</strong>. Should the Margin amount
                  in the Margin Account at any time fall below the Minimum
                  Margin Requirement or such other amount which Fons Vitae in
                  its sole discretion may from time to time prescribe, Fons
                  Vitae shall be entitled to request the Borrower by giving
                  written notice to the Borrower to provide such additional
                  Margin as determined by Fons Vitae in its discretion. The
                  Borrower undertakes to provide such additional Margin within
                  twenty-four (24) hours or such other shorter period as Fons
                  Vitae may in its sole discretion prescribe from the date of
                  such notice being given by Fons Vitae.
                </p>
                <p>
                  <strong>4.4.\tAdditional Margin.</strong> In addition and
                  without prejudice to the other provisions of this Agreement,
                  Fons Vitae shall have the right to require such additional
                  Margin in the Margin Account as and when it deems fit, where
                  the purchased Virtual Assets or Virtual Assets Derivatives
                  carried in the Margin Account are subject to unusually rapid
                  or volatile fluctuations in value, or are deemed not able to
                  be liquidated promptly, or where such Virtual Assets or
                  Virtual Assets Derivatives do not have an active market, or
                  for any other reason whatsoever. Any written notice from Fons
                  Vitae stating that any such circumstance has arisen shall be
                  deemed to be a conclusive determination of that event.
                </p>
                <p>
                  <strong>4.5.\tForced Liquidation.</strong> The Borrower
                  acknowledges and agrees that a failure to maintain the Minimum
                  Margin Requirement may result in the forced-liquidation of his
                  trading positions.
                </p>
                <p>
                  <strong>4.6.\tNo Liability.</strong> You acknowledge and agree
                  that we shall not be liable to you for any loss that you incur
                  because of:
                </p>
                <ol>
                  <li>
                    <p>
                      any failure to make a Margin Call as soon as we are
                      entitled to;
                    </p>
                  </li>
                  <li>
                    <p>
                      your failure to maintain the Minimum Margin Requirement;
                      or
                    </p>
                  </li>
                  <li>
                    <p>a forced liquidation of your trading positions.</p>
                  </li>
                </ol>
                <p>
                  <strong>
                    5.{" "}
                    <strong>
                      <strong>FINANCING COSTS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>5.1.\tInsufficient Funds</strong>. In the event of
                  insufficient funds held in the margin Account to deduct
                  interest payment on the due date of any payments due under
                  this Agreement, then subject always to and without prejudice
                  to the other rights and remedies of Fons Vitae contained in
                  this Agreement, Fons Vitae may cause the forced liquidation of
                  the trading position of the Borrower.
                </p>
                <p>
                  <strong>
                    6.{" "}
                    <strong>
                      <strong>REPAYMENT</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>6.1.\tRepayment at Fons Vitae’s Election.</strong> The
                  Borrower shall repay to Fons Vitae in full on demand made by
                  Fons Vitae at any time all or such part as may be required by
                  Fons Vitae for any Outstanding Amount, fees, and other
                  liabilities from time to time owing or outstanding to Fons
                  Vitae under this Agreement.
                </p>
                <p>
                  <strong>6.2.\tRepayment at Borrower’s Election.</strong> You
                  may repay part or all of any Outstanding Amount, fees, and
                  other liabilities from time to time owing or outstanding to
                  Fons Vitae under this Agreement at any time.
                </p>
                <p>
                  <strong>
                    7.{" "}
                    <strong>
                      <strong>DURATION AND TERMINATION</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>7.1.\tDuration.</strong> This Agreement shall continue
                  to be effective unless terminated with or without cause by:
                </p>
                <ol>
                  <li>
                    Fons Vitae by giving a written notification of such
                    termination with immediate effect to you; or
                  </li>
                  <li>
                    You by giving seven (7) days' prior written notice of such
                    termination to Fons Vitae.
                  </li>
                </ol>
                <p>
                  <strong>7.2.\tPayment of Outstanding Amounts.</strong> In the
                  event this Agreement is terminated and thus the Margin Account
                  is either terminated or closed, you undertake to forthwith pay
                  to Fons Vitae, and authorize Fons Vitae to deduct from any
                  funds held by you on the Trading Platform, all Outstanding
                  Amounts and other liabilities owing or outstanding to Fons
                  Vitae under this Agreement. In the event that there is any
                  excess, such excess funds shall be refunded to you.
                </p>
                <p>
                  <strong>7.3.\tEvent of Default</strong>. An event of default
                  occurs if:
                </p>
                <p>
                  a). you fail to pay on time any amount which is due and
                  payable under this Agreement;
                </p>
                <p>
                  b). you breach any provisions of this Agreement and/or the
                  Terms;
                </p>
                <p>
                  c). you give us information which is untrue or misleading
                  (whether by omission or otherwise);
                </p>
                <p>
                  d). any event occurs that in our opinion causes, or may cause,
                  your ability (i) to perform your obligations under this
                  Agreement and/or the Terms, (ii) to maintain the Minimum
                  Margin Requirement; and
                </p>
                <p>
                  e). if you are a natural person you die or become a person
                  who, in our opinion, is incapable of managing your affairs.
                </p>
                <p>
                  <strong>7.4.\tConsequences of Event of Default.</strong> At
                  any time after an Event of Default, we, by notice to you, may
                  declare that the Outstanding Amounts are due and payable, in
                  which case you must immediately repay the Outstanding Amounts
                  owing to us. If you do not repay the Outstanding Amounts at
                  the time required by us, we will take any action we consider
                  necessary.
                </p>
                <p>
                  <strong>7.5.\tTermination</strong>.  We may terminate this
                  Agreement at any time regardless of whether or not there has
                  been an Event of Default with or without notice. If a
                  termination of this Agreement occurs, all the provisions of
                  this Agreement will continue to operate until you have paid
                  the Outstanding Amount despite the termination of this
                  Agreement.
                </p>
                <p>
                  <strong>7.6.\tLosses.</strong> Notwithstanding the Termination
                  of this Agreement for any reason whatsoever, you shall remain
                  fully liable to Fons Vitae for all losses, interest,
                  liabilities, actions, proceedings, costs, claims and demands
                  that may be suffered or incurred by Fons Vitae in connection
                  with or arising from transactions in Virtual Assets
                  Derivatives under the Margin Account until all outstanding
                  amounts payable hereunder shall be fully recovered by Fons
                  Vitae. We shall not be responsible for any of your losses
                  resulting from the Termination of this Agreement.
                </p>
                <p>
                  <strong>
                    8.{" "}
                    <strong>
                      <strong>INDEMNIFICATION</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  You agree to indemnify and hold Fons Vitae, its shareholders,
                  subsidiaries, affiliates, agents, employees, agents,
                  successors and assigns (\"<strong>Fons Vitae Entities</strong>
                  ”) harmless from and against any and all liabilities, losses,
                  damages, costs and expenses, including attorney’s fees,
                  incurred by any of Fons Vitae Entities arising out of
                  Borrower’s failure to fully and timely perform Borrower’s
                  obligations herein or should any of Borrower’s representations
                  and warranties fail to be true and correct. You also agree to
                  pay Fons Vitae Entities promptly all damages, costs and
                  expenses, including attorney’s fees, incurred in the
                  enforcement of any of the provisions of this Agreement and any
                  other agreements between Fons Vitae and the Borrower. In
                  addition to any limitations of liability specified elsewhere
                  in this Agreement, Fons Vitae Entities shall not be held
                  liable and are released from all claims and losses incurred in
                  such regard if the claim or loss was caused or contributed to
                  by:
                </p>
                <p>
                  a). The actions or omission to act on the part of Borrower;
                </p>
                <p>
                  b). Any act or omission by any person obtaining access to the
                  Margin Account, whether or not Borrower has authorized such
                  access or not;
                </p>
                <p>
                  c). System malfunction, equipment failure (whether Borrower’s
                  equipment or Fons Vitae’s equipment), system interruption or
                  system unavailability;
                </p>
                <p>
                  d). Delays, failure or errors in implementing any instruction;
                </p>
                <p>
                  e). Inaccurate or incomplete instructions received by Fons
                  Vitae from the Borrower, or
                </p>
                <p>
                  f). Any reliance or use by the Borrower or any other third
                  party with access to Borrower’s Margin Account.
                </p>
                <p>
                  Fons Vitae Entities reserve the right to exercise sole control
                  over the defense, at your expense, of any claim subject to
                  indemnification under this section.
                </p>
                <p>
                  <strong>
                    9.{" "}
                    <strong>
                      <strong>
                        DISCLAIMER OF WARRANTIES / LIMITATION OF LIABILITY
                      </strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU
                  EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT WILL FONS
                  VITAE ENTITIES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS,
                  DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
                  REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY
                  INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR
                  SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT
                  LIMITATION, DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE,
                  PROFITS OR OTHER BUSINESSES OR FINANCIAL BENEFITS) ARISING OUT
                  OF THE SERVICES, ANY PERFORMANCE OR NON-PERFORMANCE OF THE
                  SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED
                  BY OR ON BEHALF OF FONS VITAE ENTITIES WHETHER UNDER CONTRACT,
                  STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF THE FONS
                  VITAE ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION
                  THAT SUCH DAMAGES WERE A RESULT OF FONS VITAE ENTITIES GROSS
                  NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION
                  OF LAW.
                </p>
                <p>
                  NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE LIABILITY
                  OF FONS VITAE ENTITIES AND THEIR RESPECTIVE SHAREHOLDERS,
                  MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
                  REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING OUT OF THE
                  SERVICES OFFERED BY OR ON BEHALF OF FONS VITAE ENTITIES, ANY
                  PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER
                  PRODUCT, SERVICE OR OTHER ITEM, WHETHER UNDER CONTRACT,
                  STATUTE, STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT
                  OF THE FEES PAID BY YOU TO FONS VITAE ENTITIES UNDER THIS
                  AGREEMENT IN THE TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE
                  EVENT GIVING RISE TO THE CLAIM FOR LIABILITY.
                </p>
                <p>
                  IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICES OR
                  WITH THIS AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                  DISCONTINUE USE OF THE SERVICES AND CLOSE YOUR MARGIN ACCOUNT.
                  THE SERVICES ARE PROVIDED \"AS IS\" AND WITHOUT ANY
                  REPRESENTATION OR WARRANTY, WHETHER EXPRESS OR IMPLIED. FONS
                  VITAE ENTITIES SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF
                  TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                  NON-INFRINGEMENT. THE FONS VITAE ENTITIES MAKE NO WARRANTY
                  THAT (I) THE SERVICES WILL MEET YOUR REQUIREMENTS, (II) THE
                  SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
                  OR (III) THE QUALITY, SUITABILITY AND PERFORMANCE OF ANY
                  VIRTUAL ASSETS, VIRTUAL ASSET DERIVATIVES, THE SERVICES,
                  INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
                  WILL MEET YOUR EXPECTATIONS. THE SERVICES ARE PROVIDED ON AN
                  “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATION OR
                  WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY.
                </p>
                <p>
                  <strong>
                    10.{" "}
                    <strong>
                      <strong>NO WAIVER</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  From time to time, Fons Vitae may fail to require or strictly
                  enforce compliance with relation to any provision in this
                  Agreement.  Fons Vitae may also fail to exercise any or all of
                  its rights empowered herein.  Any such failure shall not be
                  construed as a waiver or relinquishment of Fons Vitae’s right
                  to assert or rely upon any such provision or right in that or
                  in any other instance.  If applicable, an express waiver given
                  by Fons Vitae of any condition, provision, or requirement of
                  this Agreement shall not constitute a waiver of any future
                  obligation to comply with such condition, provision or
                  requirement.
                </p>
                <p>
                  <strong>
                    11.{" "}
                    <strong>
                      <strong>GOVERNING LAW AND JURISDICTION</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  This Agreement is governed and shall be interpreted by the
                  laws of Saint Vincent and the Grenadines without giving effect
                  to any conflict of laws principles that may provide for the
                  application of the law of another jurisdiction.
                </p>
                <p>
                  You agree to submit any Dispute (as defined below) to
                  arbitration in accordance with the terms of Section 12.  To
                  the extent that the agreement to arbitrate is ineffective or
                  void, you agree to submit to the exclusive jurisdiction of the
                  courts of Saint Vincent and the Grenadines.
                </p>
                <p>
                  <strong>
                    12.{" "}
                    <strong>
                      <strong>SUBMISSION TO ARBITRATION</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Any dispute, claim, suit, action, cause of action, demand, or
                  proceeding arising out of or related to these Terms, and any
                  terms announced on the Website (including with respect of
                  their validity, existence, or termination), any Services,
                  action or transaction under or contemplated by these Terms,
                  (any \"Dispute\") that is not settled by you and PowerTrade
                  within 30 days from the date that either party notifies the
                  other party in writing of the Dispute shall be referred to and
                  finally settled by arbitration. Further, the parties hereby
                  agree that:
                </p>
                <p>
                  a). To attempt informal resolution prior to any demand for
                  arbitration for at least 30 days before initiating any
                  arbitration or court proceeding. Such informal negotiations
                  commence upon receipt of written notice from you. If we cannot
                  resolve the dispute on an informal basis, you and we agree
                  that any dispute arising under this Agreement shall be finally
                  settled in binding arbitration, on an individual basis;
                </p>
                <p>
                  b). That any dispute, controversy or claim arising out of or
                  relating to the Terms, or the breach, termination or
                  invalidity thereof, shall be settled by arbitration in
                  accordance with the Singapore International Arbitration Centre
                  (“SIAC”) rules;
                </p>
                <p>c). That the number of arbitrators shall be one;</p>
                <p>
                  d). That the place of arbitration shall be the SIAC, unless
                  the Parties agree otherwise;
                </p>
                <p>
                  e). That the language to be used in the arbitral proceedings
                  shall be English;
                </p>
                <p>
                  f). That the courts in Saint Vincent and the Grenadines have
                  non-exclusive jurisdiction over any appeals of an arbitration
                  award and over any suit between the parties not subject to
                  arbitration;
                </p>
                <p>
                  g). That the arbitrator has the authority to grant any remedy
                  that would otherwise be available in court; and
                </p>
                <p>
                  h). That the parties shall split the costs and expenses of any
                  arbitration and bear their own legal costs and expenses.
                </p>
                <p>
                  <strong>
                    13.{" "}
                    <strong>
                      <strong>
                        WAIVER OF CLASS ACTION RIGHTS AND CLASS WIDE ARBITRATION
                      </strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  You and Fons Vitae agree that any claims relating to this
                  Agreement or to your relationship with Fons Vitae as a
                  Borrower of Fons Vitae (whether based in contract, tort,
                  statute, fraud, misrepresentation, or any other legal theory,
                  and whether the claims arise during or after the termination
                  of this Agreement) shall be brought against the other party in
                  an arbitration on an individual basis only and not as a
                  plaintiff or class member in a purported class or
                  representative action. You and Fons Vitae further agree to
                  waive any right for such claims to be brought, heard, or
                  arbitrated as a class, collective, representative, or private
                  attorney general action, to the extent permissible by
                  applicable law. You agree not to join with any other
                  individual or entity or group of individuals or entities for
                  the purpose of seeking to resolve the respective Disputes on a
                  consolidated or representative basis.
                </p>
                <p>
                  <strong>
                    14.{" "}
                    <strong>
                      <strong>COMPLIANCE WITH APPLICABLE LAWS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  We may delay, block or refuse to make a payment if we believe
                  on reasonable grounds that making the payment may breach any
                  applicable law, and we will incur no liability to you if we do
                  so. You agree to provide all information to us which we
                  reasonably require to comply with applicable laws. We may
                  disclose information which you provide to us where required by
                  applicable laws.
                </p>
                <p>
                  <strong>
                    15.{" "}
                    <strong>
                      <strong>PRIVACY POLICY</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Our Privacy Policy can be found{" "}
                  <a href="%22/privacy-policy/%22">here</a>.
                </p>
                <p>
                  <strong>
                    16.{" "}
                    <strong>
                      <strong>MISCELLANEOUS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>16.1.\t Assignment</strong>. You may not assign any
                  rights and/or licenses granted under this Agreement. We
                  reserve the right to assign our rights without restriction,
                  including without limitation to any of our affiliates or
                  subsidiaries, or to any successor in interest of any business
                  associated with the Services. Any attempted transfer or
                  assignment in violation hereof shall be null and void. Subject
                  to the foregoing, this Agreement will bind and inure to the
                  benefit of the parties, their successors and permitted
                  assigns.
                </p>
                <p>
                  <strong>16.2.\tSeverability</strong>. If any provision of this
                  Agreement shall be determined to be invalid or unenforceable
                  under any rule, law or regulation or any governmental agency,
                  local, state, or federal, such provision will be changed and
                  interpreted to accomplish the objectives of the provision to
                  the greatest extent possible under any applicable law and the
                  validity or enforceability of any other provision of this
                  Agreement shall not be affected.
                </p>
                <p>
                  <strong>16.3.\tForce Majeure.</strong> We shall have no
                  liability for any failure or delay resulting from any abnormal
                  or unforeseeable circumstances outside our reasonable control,
                  the consequences of which would have been unavoidable despite
                  all efforts to the contrary, including without limitation
                  governmental action or acts of terrorism, war, earthquake,
                  fire, flood, or other acts of God, labor conditions, delays or
                  failures caused by problems with another system or network,
                  mechanical breakdown or data-processing failures or where we
                  are bound by other legal obligations.
                </p>
                <p>
                  <strong>16.4.\tSurvival.</strong> Upon termination of your
                  account or this Agreement for any other reason, all rights and
                  obligations of the parties that by their nature are continuing
                  will survive such termination.
                </p>
                <p>
                  <strong>16.5.\tThird Party Rights</strong>. This Agreement is
                  not intended and shall not be construed to create any rights
                  or remedies in any parties other than you and us and any of
                  our affiliates which each shall be a third party beneficiary
                  of this Agreement, and no other person shall assert any rights
                  as a third party beneficiary hereunder.
                </p>
                <p>
                  <strong>16.6.\tNotices.</strong> All notices and demands in
                  terms of this Agreement shall be given in writing by
                  electronic mail.
                </p>
                <p>
                  <strong>
                    BORROWER ACKNOWLEDGES HAVING RECEIVED, READ AND UNDERSTOOD
                    THE FOREGOING TERMS AND HEREBY AGREES TO BE BOUND BY ALL OF
                    THE TERMS AND CONDITIONS HEREOF.
                  </strong>
                </p>
              </div>
            </section>
          </article>
        </Container>
      </ScMain>
    </Layout>
  );
};

export default TCPage;

export const Head: HeadFC = () => <title>Terms of service</title>;
